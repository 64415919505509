import { EventEmitter, Injectable } from "@angular/core";

import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { AppState } from "./app-state";

/**
 * The service to change states, like the in-audit-mode or the switch between mobile and desktop.
 */
@Injectable({
    providedIn: "root"
})
export class AppService {
    constructor() {
        this.construct();
    }

    public readonly currentVersion: string = "1.4.1";
    public readonly stage: string = "release";
    public readonly clientId: string = CryptoHelper.getUUID();
    public appLoaded: boolean = false;
    public appState: AppState = new AppState();
    public appLoadingFinished: EventEmitter<boolean> = new EventEmitter<boolean>();
    public pwaCanBeInstalledUpdated: EventEmitter<void> = new EventEmitter<void>();
    private pwaInstallPromptEvent?: any;

    private construct(): void {
        try {
            window.addEventListener("beforeinstallprompt", this.pwaStatusUpdate.bind(this));
        } catch (error) {
            // Ignore all errors, PWA features are not available.
        }
    }

    public async initialize(): Promise<void> {
        // There's nothing to do for now.
    }

    public async restartApp(): Promise<void> {
        location.reload();
    }

    public notifyAppCompletelyInitialized(): void {
        console.info("Application has been initialized.");
        this.appLoaded = true;
        this.appLoadingFinished.emit(true);
    }

    private pwaStatusUpdate(event: Event): void {
        this.pwaInstallPromptEvent = event;
        this.appState.pwaInstalled = !event;

        if (!this.appState.pwaInstalled) {
            this.pwaCanBeInstalledUpdated.emit();
        }
    }

    public installPwa(): void {
        if (this.appState.pwaInstalled || !this.pwaInstallPromptEvent?.prompt) {
            return;
        }

        this.pwaInstallPromptEvent.prompt();
        if ("userChoice" in this.pwaInstallPromptEvent && this.pwaInstallPromptEvent.userChoice.then) {
            this.pwaInstallPromptEvent.userChoice.then((choiceResult: any): void => {
                this.appState.pwaInstalled = choiceResult?.outcome == "accepted";
                this.pwaCanBeInstalledUpdated.emit();
            });
        }
    }
}
